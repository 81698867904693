<template>
  <div>
    <role-add
      :is-sidebar-active.sync="isAddNewRoleSidebarActive"
      :is-readonly.sync="isAddNewRoleSidebarInDisplayMode"
      :value.sync="activeRecord"
      :state.sync="userLoading"
      :edit-tab="editRecord"
      @reset-state="handleState"
      @open-user="handleOpenUser"
      @open-role="handleOpenRole"
      @open-process="handleOpenProcess"
      @added-new-role="refetchData"
    />
    <!-- <role-add
      :is-sidebar-active.sync="isInnerRoleSidebarActive"
      :is-readonly="true"
      :value.sync="innerRecord"
    /> -->
    <!-- <user-add
      :is-sidebar-active.sync="isCurrentUserSideBarActive"
      :is-readonly="true"
      :user-type-options="userTypeOptions"
      :auth-method-options="authMethodOptions"
      :value.sync="userData"
    /> -->
    <!-- <process-add
      :is-sidebar-active.sync="isProcessSidebarActive"
      :is-readonly="true"
      :value.sync="activeProcessRecord"
    /> -->

    <ImportCsvModal
      entity="role"
      title="Import Roles"
      @import-success="onImportSuccess"
    />

    <!-- Filters -->
    <!--    <role-list-filters-->
    <!--      :role-type-filter.sync="searchFilter.roleType"-->
    <!--      :role-type-options="roleTypeOptions"-->
    <!--    />-->

    <!-- Table Container Card -->
    <div no-body class="mb-0 container-card">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <!-- Search -->
              <entity-filter
                entity="role"
                entity-plural-slug="roles"
                :entity-field-suggestions="ROLE_ENTITY_FIELD_SUGGESTIONS"
                :search-query.sync="searchQuery"
                :selected-queries.sync="selectedQueries"
                @clear-query="clearQuery"
              />

              <transition name="slide-fade">
                <div v-if="selectedItems.length !== 0">
                  <!-- Delete Selected Items Button -->
                  <b-button
                    class="ml-1"
                    variant="danger"
                    @click="deleteSelectedRecords"
                  >
                    <span class="text-nowrap">Delete</span>
                  </b-button>
                </div>
              </transition>

              <b-button
                v-if="$can('add', 'role')"
                variant="primary"
                style="margin-left: 20px"
                @click="editRecord(null)"
              >
                <span class="text-nowrap">Add Role</span>
              </b-button>
              <b-button
                v-if="$can('import', 'role')"
                variant="primary"
                style="margin-left: 20px"
                @click="openImportCsvModal()"
              >
                <span class="text-nowrap">Import Roles</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refRoleListTable"
        class="position-relative table"
        :items="
          currentRouteName === 'my-role-list'
            ? currentUserRoles.nodes
            : roles.nodes
        "
        responsive
        sticky-column
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :no-local-sorting="true"
        :busy="isLoading"
        show-empty
        hover
        empty-text="No matching records found"
        @row-clicked="displayRecord"
      >
        <template #table-busy>
          <div class="d-flex justify-content-center mb-1">
            <b-spinner type="grow" variant="primary" label="Loading..." />
          </div>
        </template>

        <!-- Header: Select -->
        <template #head(select)="[]">
          <div class="d-flex">
            <b-form-checkbox
              style="text-align: center !important; margin-left: 5px"
              :checked="
                roles.nodes.length !== 0 &&
                  roles.nodes.length === selectedItems.length
              "
              @change="selectOrUnselectAllRows($event)"
            />
          </div>
        </template>

        <!-- Column: Select -->
        <template #cell(select)="data">
          <div class="d-flex">
            <template>
              <b-form-checkbox
                style="text-align: center !important; margin-left: 5px"
                :checked="
                  selectedItems.find(e => e.id === data.item.id) ? true : false
                "
                @change="selectOrUnselectRow($event, data.item)"
              />
            </template>
          </div>
        </template>

        <!-- Column: Filled By -->
        <template #cell(filledBy)="data">
          <span v-for="(user, index) in data.item.filledBy" :key="user.id">
            <span>{{ user.name }}</span>
            <span v-if="index < data.item.filledBy.length - 1">, </span>
          </span>
        </template>

        <!-- Column: Reports To -->
        <template #cell(reportsTo)="data">
          <span v-if="data.item.reportsTo">
            {{ truncate(data.item.reportsTo.roleHandle, 11) }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="action-buttons">
            <b-button
              v-if="$can('edit', 'role') || $can('edit', 'myRole')"
              :id="`row-${data.item.id}-edit-icon`"
              variant="flat-primary"
              class="btn-icon rounded-circle"
              @click="editRecord(data.item)"
            >
              <feather-icon icon="EditIcon" size="16" />
            </b-button>
            <b-tooltip
              title="Edit"
              class="cursor-pointer"
              :target="`row-${data.item.id}-edit-icon`"
            />

            <b-button
              v-if="$can('delete', 'role') || $can('delete', 'myRole')"
              :id="`row-${data.item.id}-delete-icon`"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="deleteRecord(data.item)"
            >
              <feather-icon icon="TrashIcon" size="16" />
            </b-button>
            <b-tooltip
              title="Delete"
              class="cursor-pointer"
              :target="`row-${data.item.id}-delete-icon`"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="3"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
class="text-muted">Showing {{ paginationMeta.from }} to {{ paginationMeta.to }} of
              {{ paginationMeta.of }} entries</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="5"
            sm="5"
            class="d-flex align-items-center justify-content-center justify-content-sm-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="
                currentRouteName === 'my-role-list'
                  ? currentUserRoles.totalCount
                  : roles.totalCount
              "
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>

          <!-- Per Page -->
          <b-col
            cols="4"
            sm="4"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              append-to-body
              :calculate-position="withPopper"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BTooltip,
    BSpinner,
    BCardText,
    BFormCheckbox,
    VBTooltip,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import Ripple from 'vue-ripple-directive'
  import { debounce } from 'vue-debounce'
  import { mapState, mapActions } from 'vuex'
  import { selectPosition } from '@core/mixins/ui/select'
  // import UserAdd from '../user/UserAdd.vue'
  // import ProcessAdd from '../process/ProcessAdd.vue'
  import ImportCsvModal from '@/views/shared/ImportCsvModal.vue'
  import { ROLE_ENTITY_FIELD_SUGGESTIONS } from '@/utils/entity-filter'
  import EntityFilter from '@/views/shared/Filter.vue'
  // import ability from '@/libs/acl/ability'
  import RoleAdd from './RoleAdd.vue'

  export default {
    components: {
      // custom components
      RoleAdd,
      ImportCsvModal,
      EntityFilter,
      // UserAdd,
      // ProcessAdd,

      // Bootstrap components
      // BCard,
      BRow,
      BCol,
      BButton,
      BTable,
      BPagination,
      BTooltip,
      BSpinner,
      // eslint-disable-next-line vue/no-unused-components
      BCardText,
      BFormCheckbox,

      // other components
      vSelect,
    },
    directives: {
      Ripple,
      'b-tooltip': VBTooltip,
    },
    mixins: [selectPosition],
    data() {
      return {
        tableColumns: [
          {
            key: 'select',
            stickyColumn: true,
            label: 'select',
            class: 'select-edit',
          },
          {
            key: 'roleHandle',
            sortable: true,
            label: 'ID',
            class: 'my-role-class',
          },
          { key: 'name', sortable: true, class: 'my-name-class' },
          {
            key: 'filledBy',
            sortable: true,
            label: 'Filled By',
            class: 'my-class',
          },
          {
            key: 'reportsTo',
            sortable: true,
            label: 'Reports to',
            class: 'my-class',
          },
          { key: 'actions', class: 'my-class action-edit' },
        ],
        isAddNewRoleSidebarActive: false,
        isAddNewRoleSidebarInDisplayMode: false,
        activeRecord: null,

        isInnerRoleSidebarActive: false,
        innerRecord: null,

        isProcessSidebarActive: false,
        activeProcessRecord: null,

        isCurrentUserSideBarActive: false,
        userData: null,
        searchFilter: {
          roleType: null,
        },
        perPageOptions: [10, 25, 50, 100],
        perPage: 10,
        currentPage: 1,
        sortBy: 'name',
        isSortDirDesc: false,

        // For filter feature
        ROLE_ENTITY_FIELD_SUGGESTIONS,
        searchQuery: [],
        selectedQueries: [],
        userTypeOptions: [
          { label: 'Admin', value: 'Admin' },
          { label: 'Manager', value: 'Manager' },
          { label: 'User', value: 'User' },
        ],
        authMethodOptions: [
          { label: 'Email / Password', value: 'Email' },
          { label: 'Provider (e.g., Google, Microsoft)', value: 'Oauth' },
          { label: 'No Auth', value: 'No' },
        ],
        userLoading: false,

        // For selecting table rows
        selectedItems: [],
      }
    },
    computed: {
      ...mapState('role', [
        'roles',
        'currentUserRoles',
        'isLoading',
        'fieldValues',
        'importRolesResult',
      ]),
      ...mapState('filter', ['filtersAccessibleToCurrentUser']),
      ...mapState('process', ['processes']),
      ...mapState('user', ['user']),
      paginationMeta() {
        let rolesToPaginate = { nodes: [], totalCount: 0 }
        if (this.currentRouteName === 'role-list') rolesToPaginate = this.roles
        else if (this.currentRouteName === 'my-role-list')
          rolesToPaginate = this.currentUserRoles
        return {
          from:
            this.perPage * (this.currentPage - 1) +
            (rolesToPaginate.nodes.length ? 1 : 0),
          to:
            this.perPage * (this.currentPage - 1) +
            rolesToPaginate.nodes.length,
          of: rolesToPaginate.totalCount,
        }
      },
      queryParams() {
        return {
          roleType: this.searchFilter.roleType,
          q: this.searchQuery,
          offset: this.perPage * (this.currentPage - 1),
          limit: this.perPage,
          sortBy: this.sortBy,
          isDesc: this.isSortDirDesc,
        }
      },
      currentRouteName() {
        return this.$route.name
      },
      isSelectAllChecked: {
        get() {
          return this.roles.nodes.length === this.selectedItems.length
        },
        set(newValue) {
          this.selectOrUnselectAllRows(newValue)
        },
      },
    },
    watch: {
      queryParams: {
        handler() {
          this.fetchRoles()
        },
        deep: true,
      },
      currentRouteName() {
        this.onLoad()
      },
      roles: {
        handler() {
          const { compact } = this.$route.query
          if (compact && this.roles.nodes[0]) {
            this.displayRecord(this.roles.nodes[0])
          }
        },
      },
      importRolesResult: {
        handler() {
          this.onLoad()
        },
      },
    },
    beforeCreate() {
      this.$store.dispatch('role/resetRoles')
    },
    created() {
      this.onLoad()
    },
    methods: {
      ...mapActions('role', [
        'getRoles',
        'getCurrentUserRoles',
        'getCurrentUserRolesStatsItems',
        'getPossibleValuesForFieldFromRoleEntity',
      ]),
      ...mapActions('filter', ['getFiltersAccessibleToCurrentUser']),
      ...mapActions('process', ['refetchProcessesIfNotLoaded']),
      ...mapActions('user', ['getUser']),
      refetchData: debounce(function cb() {
        this.fetchRoles()
      }, '300ms'),
      onLoad() {
        let assignQuery = []
        const { q } = this.$route.query

        if (q) {
          if (Array.isArray(q)) {
            assignQuery = q
          } else {
            assignQuery = [q]
          }

          this.searchQuery = assignQuery
          this.selectedQueries = assignQuery
        }

        this.suggestions = this.fieldSuggestions

        this.fetchRoles()
      },
      fetchRoles() {
        if (this.currentRouteName === 'role-list') {
          this.getRoles(this.queryParams)
        } else if (this.currentRouteName === 'my-role-list') {
          this.getCurrentUserRoles(this.queryParams)
        }
      },
      editRecord(activeRecord) {
        this.activeRecord = activeRecord
        this.isAddNewRoleSidebarActive = true
        this.isAddNewRoleSidebarInDisplayMode = false
      },
      displayRecord(activeRecord) {
        this.editRecord(activeRecord)
        this.isAddNewRoleSidebarInDisplayMode = true
      },
      handleOpenUser(id) {
        this.userLoading = true
        this.getUser({
          id,
        }).then(() => {
          this.userLoading = false
          this.userData = this.user
          this.isCurrentUserSideBarActive = true
          this.isAddNewRoleSidebarActive = true
          this.isAddNewRoleSidebarInDisplayMode = true
        })
      },
      deleteRecord(activeRecord) {
        this.$bvModal
          .msgBoxConfirm(
            <div>
              <span>Are you sure that you want to delete this role?</span>
              <br />
              <feather-icon
                icon="AlertTriangleIcon"
                class="text-warning"
                style="margin-right:5px; color:red!important;"
                size="14"
              />
              <small class="text-warning">
                Late or edited tasks for this Role will be kept, but unstarted
                future tasks will disappear
              </small>
            </div>,
            {
              title: 'Please Confirm',
              size: 'sm',
              okVariant: 'danger',
              okTitle: 'Delete',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then(value => {
            if (value) {
              this.$store.dispatch('role/removeRole', activeRecord.id)
            }
          })
      },
      openImportCsvModal() {
        this.$nextTick(() => {
          this.$bvModal.show('modal-import-csv')
        })
      },
      listGroupItems(id, array) {
        const item = [{ label: 'Not Assigned', value: `${Math.random()}` }]
        if (id) {
          const label = array.find(el => el.value === id)
          if (!label) {
            return item
          }
          return { label }
        }
        return item
      },

      // creating and saving filters
      openSaveFilterModal() {
        this.$nextTick(() => {
          this.$bvModal.show('modal-save-filter')
        })
      },
      handleOpenRole(value) {
        this.innerRecord = value
        this.isInnerRoleSidebarActive = true
      },
      handleOpenProcess(value) {
        this.activeProcessRecord = value
        this.isProcessSidebarActive = true
      },
      handleState() {
        this.userLoading = false
      },
      // for multi-select feature
      selectOrUnselectRow($event, record) {
        if ($event) {
          this.selectedItems.push(record)
        } else {
          const recordToDeleteIndex = this.selectedItems.findIndex(
            e => e.id === record.id
          )
          this.selectedItems.splice(recordToDeleteIndex, 1)
        }
      },
      selectAllRows() {
        this.selectedItems = [...this.roles.nodes]
      },
      clearSelected() {
        this.selectedItems = []
      },
      selectOrUnselectAllRows(value) {
        if (value) this.selectAllRows()
        else this.clearSelected()
      },
      deleteSelectedRecords() {
        const selectedItemsIds = this.selectedItems.map(item => item.id)
        this.$store.dispatch('role/removeRoles', selectedItemsIds).then(() => {
          this.selectedItems = []
        })
      },
      truncate(str, n) {
        return str.length > n ? `${str.substr(0, n - 1)}...` : str
      },
      clearQuery() {
        this.$router.replace({ query: null })
      },
      onImportSuccess() {
        this.refetchData()
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../@core/scss/vue/libs/vue-select';
  @import '../../@core/scss/vue/libs/vue-autosuggest';

  .per-page-selector {
    width: 90px;
  }
  .action-buttons {
    margin-left: -10px;
    button {
      margin-right: 4px;
    }
  }

  .container-card {
    overflow: auto;
    background-color: white;
    border: none;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    border-radius: 0.428rem;
    min-height: 83vh;
  }
</style>

<style lang="scss">
  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .my-class {
    @extend .truncate;
    width: 200px;
    max-width: 200px;
    vertical-align: middle !important;
  }
  .my-role-class {
    @extend .truncate;
    width: 150px;
    max-width: 150px;
    min-width: 150px;
  }
  .my-name-class {
    @extend .truncate;
    max-width: 200px;
  }
  th.select-edit {
    width: 50px !important;
    max-width: 50px !important;
    padding: 10.8px !important;
  }
  td.select-edit {
    width: 50px !important;
    max-width: 50px !important;
    padding: 10.8px !important;
  }
  th.my-class {
    @extend .my-class;
    padding-left: 5px;
  }
  th.my-role-class {
    @extend .my-role-class;
    padding-left: 5px;
  }
  td {
    padding: 0.72rem !important;
  }
  td.action-edit {
    white-space: pre-wrap !important;
    width: 100px;
    max-width: 100px;
    min-width: 100px;
  }
  th.action-edit {
    white-space: pre-wrap !important;
    width: 100px;
    max-width: 100px;
    min-width: 100px;
  }
</style>
